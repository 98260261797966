import React, { Component } from 'react';
import '../css/app.css';

class App extends Component {
  render() {
    const domain = process.env.REACT_APP_ENV === 'staging'
                   ? 'https://capitalonemoneycoaching.demo.coconutcalendar.com'
                   : 'https://capitalonecafe.coconutcalendar.com';
    return (

      <React.Fragment>
        <div className="cv-wrapper">
            <iframe title={"Coconut Software Online Bookings"} src={`${domain}${window.location.pathname}${window.location.search}${window.location.hash}`} width="100%" height="100%" seamless frameBorder="0" allow="geolocation"></iframe>
        </div>
      </React.Fragment>
    );
  }
}

export default App;
